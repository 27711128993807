<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">

    <!-- Swatch Column -->
    <ng-container matColumnDef="swatch">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let color"> <span class="swatch" [style.backgroundColor]="this.toRgb(color)"></span> </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let color"> {{color.name}} </td>
    </ng-container>

    <!-- Rgb Column -->
    <ng-container matColumnDef="rgb">
      <th mat-header-cell *matHeaderCellDef> RGB </th>
      <td mat-cell *matCellDef="let color"> {{toRgb(color)}} </td>
    </ng-container>

    <!-- Hex Column -->
    <ng-container matColumnDef="hex">
      <th mat-header-cell *matHeaderCellDef> Hex </th>
      <td mat-cell *matCellDef="let color"> {{toHex(color)}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>
